import authService from '@/services/auth';

export default {
  name: 'suspended',
  data() {
    return {};
  },
  methods: {
    onLogout() {
      authService.logout();
      this.$router.replace('/login');
    }
  },

  mounted() {
    document.getElementById('mainMenu').style.display = 'none';
  },
  beforeDestroy() {
    document.getElementById('mainMenu').style.display = 'block';
  }
};
