<template>
  <div id="approvalPage">
    <!-- // logout button -->
    <el-button slot="reference" type="danger" size="small" @click="onLogout()" style="position: fixed; top: 0; right: 10px; margin: 20px">Logout</el-button>

    <div class="pageContainer">
      <!-- Mobile Only - Hero Image -->
      <img class="mobileHeroImg" src="https://static.growlytics.in/dashboard-assets/assets/img/approval-page-image.png" />

      <!-- Left Side Image -->
      <div class="leftSide">
        <div class="header">Account On Hold</div>

        <div class="body">
          Hi {{ $store.state.token.firstName }}, Your Growlytics account has been put on hold because of past due payments. <br /><br />
          To reactivate your account, please reach out to Growlytics support team. After cleaering dues, it might take up to 24 hours to reactive your account. <br /><br />
          If your account stays on hold for more than 15 days, account will be considered for termination. In addition, all content and data in your account is deleted.<br /><br />
          If your account isn't reactivated 24 hours after you pay all outstanding invoices, please contact Growlytics Support.
        </div>
      </div>
      <!-- Right Side Image -->
      <div class="rightSide">
        <img src="https://static.growlytics.in/dashboard-assets/assets/img/approval-page-image.png" />
      </div>
    </div>
  </div>
</template>
<script>
import accountSuspendedComponent from './accountSuspendedComponent';
export default accountSuspendedComponent;
</script>

<style lang="scss" src="./accountSuspended.scss"></style>
